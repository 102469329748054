import React, { useEffect, useRef, useState } from 'react'
import { Modal, Form, Input, notification, Table } from 'antd'
import moment from 'moment'
import { memberships } from 'apiv2'
import c from 'classnames'
import deleteIcon from 'images/deleteUser.svg'
import { useParams } from 'react-router-dom'
import { handleConfirmModal } from 'utils'
import { getMembershipRoster } from 'modules/memberships/memberships-actions'
import calendarIcon from 'images/calendarIcon.svg'
import CrossMark from 'images/date-cross.svg'
import InputMask from 'react-input-mask'
import { mobileValidator } from 'modules/utils/validators'
import { CustomButton } from 'components'
import { useDispatch } from 'react-redux'
import './index.styl'
import MobileTable from '../../pages/MemberOffice/MobileTable'

const { Item } = Form

const getOffset = (current, limit) => current * limit - limit
const getParams = (current, limit) => ({ limit, skip: getOffset(current, limit) })

const ModalEditMember = ({ visible, setVisible, avatar, userInfo, signUpFields, membershipID, parentApproval, rosterDoB, page, limit, searchInput, type }) => {
  const { eventID } = useParams()
  const params = getParams(page, limit)
  const [isAdult, setIsAdult] = useState(true)
  const [loading, setLoading] = useState(false)
  const [birthDate, setBirthDate] = useState(
    userInfo?.dateOfBirth ? moment(userInfo.dateOfBirth).format('MM/YYYY') : null,
  )
  const [membershipTickets, setMembershipTickets] = useState(userInfo?.tickets || [])
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const inputRef = useRef(null)

  useEffect(() => {
    if (userInfo?.dateOfBirth) {
      const birthMoment = moment(userInfo.dateOfBirth)
      const age = moment().diff(birthMoment, 'years')
      setIsAdult(age >= 18)
    }
  }, [userInfo?.dateOfBirth])
  const handleDateChange = e => {
    const date = e.target.value
    setBirthDate(date)
    const birthMoment = moment(date, 'MM/YYYY')
    const age = moment().diff(birthMoment, 'years')
    if (/^\d{2}\/\d{4}$/.test(date) && !isNaN(age) && age >= 0) {
      setIsAdult(age >= 18)
    } else {
      setIsAdult(true)
    }
  }
  const validateBirthDate = (_, value) => {
    const [month, year] = value?.split('/')
    if (month && year) {
      const monthNum = parseInt(month, 10)
      if (monthNum < 1 || monthNum > 12) {
        return Promise.reject(new Error('Enter a valid month (01-12)'))
      }

      const birthMoment = moment(`${year}-${month}-01`)
      const currentDate = moment()
      const age = currentDate.diff(birthMoment, 'years')

      if (monthNum < 1 || monthNum > 12) {
        return Promise.reject(new Error('Enter a valid month (01-12)'))
      }

      if (birthMoment.isAfter(currentDate)) {
        return Promise.reject(new Error('Date cannot be in the future'))
      }

      if (age > 100) {
        return Promise.reject(new Error('Age cannot exceed 100 years'))
      }

      return Promise.resolve()
    }
  }
  const submitForm = value => {
    if (birthDate && !value.birthDate || birthDate && !/^\d{2}\/\d{4}$/.test(value.birthDate)) {
      notification.warning({
        message: 'Enter Birth Month/Year in MM/YYYY format',
      })
      return
    }
    setLoading(true)
    const fieldData = signUpFields
      ?.map(field => {
        const fieldValue = value[field.fieldName] !== undefined ? value[field.fieldName] : undefined
        return {
          id: field.id,
          value: fieldValue,
        }
      })
      .filter(field => {
        return field.value !== undefined
      })

    const dateTransform = date => {
      const [month, year] = date?.split('/')
      return `${year}-${month}`
    }

    const obj = {
      membershipID,
      signupData: fieldData || [],
      dob: value.birthDate ? dateTransform(value.birthDate) : undefined,
      tickets: membershipTickets.map(t => t.ticketPassID),
    }

    if (value.name || value.email || value.phone) {
      obj.parentData = {
        name: value.name,
        email: value.email,
        phone: value.phone,
      }
    }

    dispatch(memberships.editMemberRoster(eventID, obj)
      .then(() => {
        dispatch(getMembershipRoster(eventID, params.limit, params.skip, searchInput.trim(), type))
        setVisible(false)
      })
      .catch(err => notification.warning({ message: err?.response?.data?.error || err?.response?.data?.message }))
      .finally(() => setLoading(false)))
  }

  const deleteMember = ticketPassID => {
    const onOk = () => {
      if (!ticketPassID) return

      const updatedTickets = membershipTickets.filter(ticket => ticket.ticketPassID !== ticketPassID)

      setMembershipTickets(updatedTickets)

      notification.success({ message: 'Ticket will successfully removed after saved' })
    }
    handleConfirmModal('Are you sure you want to delete this membership?', onOk)
  }

  const columns =
     [
       {
         title: 'Membership',
         dataIndex: 'ticketName',
         key: 'ticketName',
       },
       {
         title: 'Date',
         dataIndex: 'createdAt',
         key: 'createdAt',
         render: (text, record, index) => {
           return <>{moment(record.date).format('MMM D, YYYY')}</>
         },
       },
       {
         title: 'Total Scans',
         dataIndex: 'scanCount',
         key: 'scanCount',
         align: 'center',
         width: '27%',
       },
       window.innerWidth > 550 ? {
         title: 'Delete',
         dataIndex: 'delete',
         key: 'delete',
         align: 'right',
         render: (text, record, index) => {
           return (
             <div>
               <img
                 src={deleteIcon}
                 onClick={() => deleteMember(record.ticketPassID)}
               />
             </div>)
         },
       } : {
         dataIndex: 'delete',
         key: 'delete',
         align: 'right',
         render: (text, record, index) => {
           return (
             <div onClick={() => deleteMember(record.ticketPassID)} className='deleteMembershipMobileButton'>
               Delete Membership
             </div>)
         },
       },
     ]

  const clearDate = () => {
    setBirthDate('')
    setIsAdult(true)

    form.setFieldsValue({ birthDate: '' })

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 0)
  }
  const dateIcon = birthDate && birthDate !== '__/____' ? (
    <img src={CrossMark} alt='Clear Date' className='clearDateInput' onClick={clearDate} />
  ) : (
    <img src={calendarIcon} alt=' ' />
  )
  const showParentPending = parentApproval && userInfo?.parentApproval === 'PENDING'
  return (
    <Modal
      className='modal-edit-member'
      title='Edit Member'
      visible={visible}
      width={564}
      onCancel={() => setVisible(false)}
      footer={null}
      centered
      closable={!loading}
      destroyOnClose
    >
      <Form onFinish={submitForm} layout='vertical' form={form} id='edit-member'>
        <div className='editMemberWrapper'>
          <div className='editAvatar'>
            {avatar}
          </div>
          <div className='editFullName'>
            {userInfo?.fullName}
          </div>
        </div>
        <div className='grid-container'>
          {rosterDoB &&
            <Item
              rules={[
                { required: true, message: 'Enter Birth Month/Year' },
                { validator: validateBirthDate },
              ]}
              label='Birth Month/Year'
              initialValue={moment(userInfo.dateOfBirth).format('MM/YYYY')}
              name='birthDate'
              className='defaultInputLabel item'
            >
              <InputMask
                mask='99/9999'
                value={birthDate}
                onChange={handleDateChange}
                placeholder='MM/YYYY'
                suffix={dateIcon}
              >
                {inputProps => <Input {...inputProps} ref={inputRef} className='darkBg dateOfBirthInput' />}
              </InputMask>
            </Item>}
          {birthDate && showParentPending && !isAdult && (
            <>
              <Item
                rules={[{ required: true, message: 'Please enter parent name!' }]}
                normalize={v => v.trimStart()}
                name='name'
                label='Parent Name'
                className='item'
              >
                <Input className='darkBg' maxLength={32} />
              </Item>
              <Item
                name='phone'
                label='Parent Phone'
                className='defaultInputLabel haveMargin mobile'
                rules={[{ required: true, message: 'Please enter phone number' }, mobileValidator]}
              >
                <Input className='darkBg' type='number' />
              </Item>
              <Item
                rules={[
                  { required: true, message: 'Please enter email!' },
                  { type: 'email', message: 'Please enter a correct email!', transform: value => value?.trim() },
                ]}
                label='Parent Email'
                className='defaultInputLabel email'
                name='email'
                normalize={v => v.trimStart()}
              >
                <Input className='darkBg' />
              </Item>
            </>
          )}
          {signUpFields?.map(field => (
            <div key={field.id}>
              {field?.minorOnly && !isAdult && birthDate && (
                <Item
                  rules={[{ required: field.isRequired, message: `Please enter ${field?.fieldName}!` }]}
                  normalize={v => v.trimStart()}
                  name={field?.fieldName}
                  initialValue={field?.fieldValue}
                  label={field?.fieldName}
                  className='item'
                >
                  <Input className='darkBg' maxLength={32} />
                </Item>
              )}
              {!field?.minorOnly &&
                <Item
                  rules={[{ required: field.isRequired, message: `Please enter ${field?.fieldName}!` }]}
                  normalize={v => v.trimStart()}
                  name={field?.fieldName}
                  initialValue={field?.fieldValue}
                  label={field?.fieldName}
                  className='item'
                >
                  <Input className='darkBg' maxLength={32} />
                </Item>}
            </div>
          ))}
        </div>
        <>
          <div className={c('editMembershipsTableTitle', { isActiveMembershipFields: !!signUpFields.length || birthDate })}>Memberships</div>
          <div>
            {window.innerWidth > 550
              ? (
                <Table
                  pagination={false}
                  rowKey='_id'
                  sticky
                  loading={loading}
                  className='edit-memberships-table'
                  columns={columns}
                  dataSource={!loading ? membershipTickets : null}
                  locale={{ emptyText: 'No memberships' }}
                />
              ) : (
                <div>
                  <MobileTable columns={columns} data={!loading ? membershipTickets : null} loading={loading} emptyText='No memberships' />
                </div>
              )}
          </div>
        </>
        <div className='editMembershipBtnWrapper'>
          <CustomButton onClick={() => setVisible(false)} className='cancelInfoBtn' text='Cancel' type='white' />
          <CustomButton className='submitInfoBtn' text='Save Changes' htmlType='submit' type='dark' loading={loading} />
        </div>
      </Form>
    </Modal>
  )
}

export default ModalEditMember
