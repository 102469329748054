/* eslint-disable indent */
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getMembershipRoster, approveMembershipRequest, disableMembershipMember } from 'modules/memberships/memberships-actions'
import { Table, Input, Spin, Tabs, Pagination } from 'antd'
import SearchIcon from 'images/input-search.svg'
import { throttle } from 'lodash'
import './index.styl'
import MobileTable from '../../MobileTable'
import deleteIcon from 'images/deleteUser.svg'
import resendIcon from 'images/unbanUser.svg'
import { MemberCard } from '../../../../components/MemberCard'
import { deleteMembershipMember, inviteUser, unblockMembershipMember } from '../../../../modules/memberships/memberships-actions'
import { rosterTypes } from '../../../../utils/constants'
import { format } from 'date-fns'
import MainButton from '../../../../components/MainButton'
import { ModalInviteMember } from '../../../../components'

const { TabPane } = Tabs
const throttleFunc = fn => throttle(fn, 300, { trailing: false })

const getOffset = (current, limit) => current * limit - limit
const getParams = (current, limit) => ({ limit, skip: getOffset(current, limit) })

const MembershipRoster = () => {
  const defLimit = 3
  const { eventID } = useParams()
  const containerRef = useRef(null)
  const { soldMemberDetails } = useSelector(state => state.memberships)
  const dispatch = useDispatch()

  const [tableData, setTableData] = useState([])
  const [limit, setLimit] = useState(defLimit)
  const [type, setType] = useState(rosterTypes.APPROVED)

  const [tableDesc, setTableDesc] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchInput, setSearchInput] = useState('')
  const [loading, setLoading] = useState(false)
  const [visibleModal, setVisibleModal] = useState(false)

  const handlePagination = (page, pageSize) => {
    setLoading(true)
    setCurrentPage(page)
    setLimit(pageSize)
    fetchEventData(page, pageSize)
  }

  const fetchEventData = (page = currentPage, pageSize = limit, searchValue = searchInput) => {
    const params = getParams(page, pageSize)
    dispatch(getMembershipRoster(eventID, params.limit, params.skip, searchValue.trim(), type))
  }

  let requestDelay
  const onSearch = e => {
    const newSearchValue = e.target.value
    setSearchInput(newSearchValue)

    if (requestDelay) clearTimeout(requestDelay)

    requestDelay = setTimeout(() => {
      fetchEventData(currentPage, limit, newSearchValue.trim())
    }, 700)
  }
  const disableMember = membershipID => {
    setLoading(true)
    dispatch(disableMembershipMember(eventID, membershipID)).then(() => { fetchEventData() })
  }
  const unblockMember = membershipID => {
    setLoading(true)
    dispatch(unblockMembershipMember(eventID, membershipID)).then(() => { fetchEventData() })
  }

  const deleteMember = membershipID => {
    setLoading(true)
    dispatch(deleteMembershipMember(eventID, membershipID)).then(() => { fetchEventData() })
  }
  const approveMember = membershipID => {
    setLoading(true)
    dispatch(approveMembershipRequest(eventID, membershipID)).then(() => { fetchEventData() })
  }

  const sendInvite = email => {
    setLoading(true)
    dispatch(inviteUser(eventID, email)).then(() => { fetchEventData() })
  }
  const onTabChange = key => {
    setLoading(true)
    setType(key)
    setLimit(defLimit)
    setCurrentPage(1)
  }

  const tableHeader = (
    <div className='tableHeader'>
      <Tabs onChange={onTabChange} type='card'>
        <TabPane tab='Members' key={rosterTypes.APPROVED} />
        <TabPane tab='Sent Invitations' key={rosterTypes.IN_INVITE} />
        <TabPane tab='Recieved Requests' key={rosterTypes.PENDING} />
      </Tabs>
      <>
        <span className='border' />
        <Input
          prefix={<img alt='search icon' src={SearchIcon} />}
          className='inputSearch noFocusBorder'
          onChange={throttleFunc(onSearch)}
          placeholder='Search'
          value={searchInput}
          allowClear
        />
      </>
    </div>
  )
  const columns = useMemo(() =>
    type === rosterTypes.IN_INVITE ? [
      {
        title: 'Invitation Send Date',
        dataIndex: 'sendDate',
        key: 'sendDate',
        render: (text, record, index) => {
          return <>{format(new Date(record.userInfo.invitationSendDate), 'MMM d, yyyy')}</>
        },
      },
      {
        title: 'Invited Member Email',
        dataIndex: 'email',
        align: 'center',
        key: 'email',
        render: (text, record, index) => {
          return <>{record.userInfo.email}</>
        },
      },
      {
        title: 'Actions',
        dataIndex: 'Actions',
        key: 'actions',
        align: 'right',
        render: (text, record, index) => {
          return (
            <div>
              <img
                src={resendIcon}
                onClick={() => sendInvite(record.userInfo.email)}
              />
              <img
                src={deleteIcon}
                onClick={() => deleteMember(record.membershipID)}
              />
            </div>)
        },
      },
    ] : [{
      dataIndex: 'userInfo',
      key: 'userInfo',
      render: (text, record, index) => {
        if (record.userInfo) {
        return (
          <MemberCard
            data={record}
            type={type}
            unblockMember={unblockMember}
            deleteMember={deleteMember}
            approveMember={approveMember}
            disableMember={disableMember}
            page={currentPage}
            limit={limit}
            searchInput={searchInput}
          />
        )
        }
      },
    },
    ], [type])

  useEffect(() => {
    fetchEventData()
  }, [type])
  useEffect(() => {
    setTableData(soldMemberDetails.data)
    setTableDesc(soldMemberDetails.total)
    setLoading(false)
  }, [soldMemberDetails])

  return (
    <>
      <div className='wrapperMembershipRoster' ref={containerRef}>
        <div className='roster-header'>
          <div className='roster-title'>Membership Roster</div>
          <div>
            <MainButton text='Invite Member' className='invite-button' onClick={() => setVisibleModal(true)} />
            {/* <MainButton text='Invite Member'/> */}
          </div>
        </div>
        <Spin spinning={loading} indicator={null}>
          <div>
            {window.innerWidth > 550
              ? (
                <Table
                  pagination={false}
                  rowKey='_id'
                  sticky
                  loading={loading}
                  title={() => tableHeader}
                  className={type !== rosterTypes.IN_INVITE ? 'memberships-table' : 'pendings-table'}
                  columns={columns}
                  dataSource={!loading ? tableData : null}
                />
              ) : (
                <div>
                  {tableHeader}
                  <MobileTable columns={columns} data={!loading ? tableData : null} loading={loading} />
                </div>
              )}
          </div>
        </Spin>
        {tableDesc && tableDesc > 1 ? (
          <Pagination
            className='roaster-pagination'
            showSizeChanger={false}
            onChange={handlePagination}
            disabled={loading}
            current={currentPage}
            pageSize={limit}
            total={tableDesc}
          />
        ) : null}
      </div>
      <ModalInviteMember visible={visibleModal} setVisible={setVisibleModal} sendInvite={sendInvite} />
    </>
  )
}

export default MembershipRoster
