import React from 'react'
import { Empty, Spin } from 'antd'
import c from 'classnames'
import './index.styl'

const MobileTable = ({ data, columns, loading, onRowClick = () => { }, noGutter = false, emptyText = 'No data' }) => {
  const renderRow = (item, idx) => (
    <div key={idx} className='mobileTableContainer'>
      {columns.map(({ title, dataIndex, key, render }) => (
        <div
          key={`${dataIndex || key}${idx}`}
          className={c('mobileTableRow', { fullContent: !title })}
          onClick={event => !!title ? onRowClick(event, item) : {}}
        >
          {title && <div className='mobileTableRowLabel'>{title}</div>}
          <div className='mobileTableRowText'>
            {render
              ? render(item[key], item, idx)
              : item[dataIndex || key] ?? ''}
          </div>
        </div>
      ))}
    </div>
  )
  return (
    <div className={c('mobileTableRoot', { noGutter })}>
      {loading
        ? <Spin />
        : data?.length
          ? data.map(renderRow)
          : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyText} />}
    </div>
  )
}

export default MobileTable
