import React, { useState } from 'react'
import { Avatar, Card, Descriptions, Typography } from 'antd'
import { useSelector } from 'react-redux'
import './index.styl'
import { handleConfirmModal } from 'utils'
import { format } from 'date-fns'
import moment from 'moment'
import disableIcon from 'images/disableUser.svg'
import { UserOutlined } from '@ant-design/icons'
import deleteIcon from 'images/deleteUser.svg'
import unbanIcon from 'images/unbanUser.svg'
import editMember from 'images/editMemberRoster.svg'
import MainButton from '../MainButton'
import { rosterTypes } from '../../utils/constants'
import c from 'classnames'
import ModalEditMember from '../ModalEditMember'

export const MemberCard = ({ data, type, disableMember, unblockMember, deleteMember, approveMember, page, limit, searchInput }) => {
  const { soldMemberDetails } = useSelector(state => state.memberships)
  const membershipID = data.membershipID
  const userInfo = data.userInfo
  const signUpFields = data.signupFields
  const rosterDoB = soldMemberDetails?.rosterDoB
  const parentApproval = soldMemberDetails?.parentApproval
  const [visible, setVisible] = useState(false)
  const getFullYears = dateOfBirth => {
    if (!dateOfBirth) {
      return '-'
    }
    const now = moment()
    const dob = moment(new Date(dateOfBirth))
    return now.diff(dob, 'years')
  }
  const avatar = userInfo?.image ? <Avatar size={40} src={userInfo.image?.original} /> : <Avatar size={40} icon={<UserOutlined />} />
  const onDeleteMember = () => {
    if (userInfo?.hasActiveSubscription) {
      const onOk = () => {
        deleteMember(membershipID)
      }
      handleConfirmModal('Are you sure you want to delete member with active subscription?', onOk)
    } else {
      deleteMember(membershipID)
    }
  }
  const parentApprovalStatus = userInfo?.parentApproval === 'APPROVED' ? 'Approved By Parent' : userInfo?.parentApproval === 'PENDING' ? 'Pending For Parent Approval' : userInfo?.parentApproval === 'DECLINED' ? 'Declined By Parent' : null
  return (
    <>
      <Card className='member-card' bordered={false}>
        <div
          className={c('parentApproveStatusWrapper',
            { isApprovedMember: userInfo?.parentApproval === 'APPROVED' },
            { isPendingMember: userInfo?.parentApproval === 'PENDING' },
            { isDeclinedMember: userInfo?.parentApproval === 'DECLINED' })}
          style={{ color: userInfo?.parentApproval === 'PENDING' ? '#1A1D24' : '#FFFFFF' }}
        >{parentApprovalStatus}
        </div>
        <div className='main-content'>
          <div className='avatar-wrapper'>
            {avatar}
          </div>
          <div>
            <Typography className='title sub-title'>Member info</Typography>
            <Descriptions
              column={{
                xxl: 7,
                xl: 4,
                lg: 3,
                md: 1,
                sm: 1,
                xs: 1,
              }}
              colon={false}
              layout='vertical'
              className='descriptions'
            >
              <Descriptions.Item label='User Name'>
                {userInfo.userName}
              </Descriptions.Item>
              <Descriptions.Item label='Full Name'>
                {userInfo.fullName}
              </Descriptions.Item>
              <Descriptions.Item label='Phone Number'>
                {userInfo.phone}
              </Descriptions.Item>
              <Descriptions.Item label='Email'>
                {userInfo.email}
              </Descriptions.Item>
              {rosterDoB &&
                <>
                  <Descriptions.Item label='Birth Month/Year'>
                    {userInfo?.dateOfBirth ? format(new Date(userInfo.dateOfBirth), 'MMM, yyyy') : '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label='Age'>
                    {getFullYears(userInfo.dateOfBirth)}
                  </Descriptions.Item>
                </>}
              <Descriptions.Item label='Membership'>
                {userInfo.tickets.map(category => (
                  <>
                    {category.ticketName}
                    <br />
                  </>
                ))}
              </Descriptions.Item>
            </Descriptions>
            {signUpFields.length > 0 && (
              <>
                <Typography className='title sub-title additional-info'>
                  Additional Info
                </Typography>
                <Descriptions
                  colon={false}
                  column={{
                    xxl: 7,
                    xl: 4,
                    lg: 3,
                    md: 1,
                    sm: 1,
                    xs: 1,
                  }}
                  layout='vertical'
                  className='descriptions'
                >
                  {signUpFields.map((field, i) => (
                    <Descriptions.Item key={i} colon={false} label={field.fieldName}>
                      {field.fieldValue}
                    </Descriptions.Item>
                  ))}
                </Descriptions>
              </>
            )}
          </div>
        </div>
        {type === rosterTypes.APPROVED && (
          <div className='control-panel'>
            {userInfo.isDisabled ? (
              <div>
                <MainButton text='Inactive' className='btn' />
                <img
                  src={unbanIcon}
                  onClick={() => unblockMember(membershipID)}
                />
              </div>
            ) : (
              <div>
                <img
                  src={disableIcon}
                  onClick={() => disableMember(membershipID)}
                />
                <img
                  src={deleteIcon}
                  onClick={onDeleteMember}
                />
                <img
                  src={editMember}
                  onClick={() => setVisible(true)}
                />
              </div>
            )}
          </div>
        )}
        {type === rosterTypes.PENDING && (
          <div className='invite-panel'>
            <div>
              <MainButton text={userInfo?.parentApproval === 'DECLINED' ? 'Approve Anyway' : 'Approve'} className='btn' onClick={() => approveMember(membershipID)} />
              <img
                src={deleteIcon}
                onClick={() => deleteMember(membershipID)}
              />
              <img
                src={editMember}
                onClick={() => setVisible(true)}
              />
            </div>
          </div>
        )}
      </Card>
      <ModalEditMember type={type} page={page} limit={limit} searchInput={searchInput} parentApproval={parentApproval} rosterDoB={rosterDoB} membershipID={membershipID} signUpFields={signUpFields} userInfo={userInfo} avatar={avatar} visible={visible} setVisible={setVisible} />
    </>
  )
}
