import React from 'react'
import { Modal } from 'antd'
import { CustomButton } from 'components'
import './index.styl'

const ModalTLFees = ({ visible, setVisible }) => {
  return (
    <Modal
      className='modal-tl-fees'
      title='TicketLyfe Fees '
      visible={visible}
      width={472}
      onCancel={() => setVisible(false)}
      footer={null}
      centered
      destroyOnClose
    >
      <div>
        <div className='mainTitle'>Ticketed admission, Tables</div>
        <div className='descriptionSection'>
          <div className='description'>Up to $100</div>
          <div className='percent'>$1</div>
        </div>
        <div className='descriptionSection'>
          <div className='description'>Over $100</div>
          <div className='percent'>1%</div>
        </div>
        <div className='title'>Other tickets</div>
        <div className='descriptionSection'>
          <div className='description'>Raffle</div>
          <div className='percent'>1%</div>
        </div>
        <div className='descriptionSection'>
          <div className='description'>Item</div>
          <div className='percent'>1%</div>
        </div>
        <div className='descriptionSection'>
          <div className='description'>PPV Live Stream</div>
          <div className='percent'>$2</div>
        </div>
        <div className='buttonWrapper'>
          <CustomButton className='submitInfoBtn' text='Ok' onClick={() => setVisible(false)} htmlType='submit' type='dark' />
        </div>
      </div>
    </Modal>
  )
}

export default ModalTLFees
