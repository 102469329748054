/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Skeleton } from 'antd'
import { LoginGuest, FormPayment } from 'components'
import { ReactComponent as InfoIcon } from '../../images/infoIcon.svg'
import Category from './Category'
import StepsModal from './StepsModal'
import { Login, Tickets } from './Screens'
import './index.styl'
import cardImg from 'images/card.png'
import moment from 'moment'
import { payment as paymentApi } from 'apiv2'
import calendar from 'images/Calendar.svg'
import closeIconSvg from 'images/Crossmark.svg'
import { getFee } from '../../modules/events/events-actions'

const ModalBuyTicket = ({ orderedTickets = [], orderedBundles = [], inviteType, totalPriceBundles = 0, totalPriceTickets, orderedSeats = [], updateFunc, visible = false, setVisible, img, donation, eventID, callback = () => { }, currencySymbol, action, quantity, totalPriceSeats = 0 }) => {
  const { user } = useSelector(state => state.session)
  const {
    payment,
  } = useSelector(state => state)
  const [screen, setScreen] = useState(user ? 'payment' : 'login')
  const [selectedOption, setSelectedOptions] = useState('Stripe')
  const [isAuthorizedBuy, setAuthorizedBuy] = useState(!!user)
  const [preorderCode, setPreorderCode] = useState('')
  const [fee, setFee] = useState([])
  const [feeLoaded, setFeeLoaded] = useState(false)
  const { eventName, startDate, currency, inviterID, fees } = useSelector(state => state.events.currentEvent)
  const [donationOnly, setDonationOnly] = useState(false)
  const [categories, setCategories] = useState([])
  const [seats, setSeats] = useState([])
  const [bundles, setBundles] = useState([])
  const [totalPriceFinish, setTotalPriceFinish] = useState(donation || 0)
  const [isInit, setIsInit] = useState(false)
  const dispatch = useDispatch()
  const orderedSeatsResult = {}
  orderedSeats.forEach(s => {
    orderedSeatsResult[s.seatCategoryID] = { ...orderedSeatsResult[s.seatCategoryID], seatCategoryID: s.seatCategoryID, price: s.price, seats: [...(orderedSeatsResult[s.seatCategoryID]?.seats || []), { seatID: s.seatId, guestReservationToken: s?.guestReservationToken }] }
  })

  const onCancel = () => {
    setScreen('login')
    setVisible(false)
  }
  const onTicketsBought = scr => {
    callback()
    onChangeScreen(scr)
  }
  const onChangeScreen = scr => setScreen(scr)
  const handleAuthorization = flag => {
    return new Promise(resolve => {
      setFeeLoaded(false)
      const ticketDetails = orderedTickets.map(i => {
        const isEarlyBirdActive = i.isEarlyBird && (moment() <= moment(i?.earlyBirdDate))
        return {
          ticketID: i.id,
          quantity: i.count,
          price: isEarlyBirdActive ? i.earlyBirdPrice : i.price,
        }
      })
      const bundleDetails = orderedBundles.map(i => ({
        bundleID: i.id,
        quantity: i.count,
        price: i.price,
      }))
      const seatsDetailsToFee = Object.values(orderedSeatsResult).map(seat => {
        return {
          ticketID: seat.seatCategoryID,
          price: seat.price,
          quantity: seat.seats.length,
        }
      })
      const ticketsToFee = orderedTickets.map(i => {
        const isEarlyBirdActive = i.isEarlyBird && (moment() <= moment(i?.earlyBirdDate))
        return {
          quantity: i.count,
          price: i?.isDonate ? i.total : isEarlyBirdActive ? i.earlyBirdPrice : i.price,
          isStreaming: i.isStreaming,
          ticketID: i.id,
        }
      })
      const bundlesToFee = orderedBundles.map(i => {
        return {
          quantity: i.count,
          price: i?.isDonate ? i.total : i.price,
          isStreaming: i?.isStreaming,
          bundleID: i.id,
        }
      })

      if (donation) {
        ticketsToFee.push({ quantity: 1, price: donation, isDonation: true })
      }

      const ticketsAndSeatToFee = [...seatsDetailsToFee, ...ticketsToFee, ...bundlesToFee]

      if (isAuthorizedBuy !== flag || !feeLoaded) {
        selectedOption === 'Stripe'
          ? dispatch(getFee(eventID, user ? 'USER' : 'GUEST', ticketsAndSeatToFee, currency, 'Stripe'))
            .then(res => {
              // Filter out 'TicketLyfe Fee' if TLFee is 'PROMOTER'
              const filteredFee = res.filter(item => !(fees?.TLFee === 'PROMOTER' && item.name === 'TicketLyfe Fee'))

              // Filter out 'Payment Processing Fee' if paymentProcessorFee is 'PROMOTER'
              const filteredFeeWithPaymentProcessing = filteredFee.filter(item => !(fees?.paymentProcessorFee === 'PROMOTER' && item.name === 'Payment Processing Fee'))

              setFee(filteredFeeWithPaymentProcessing.map(item => ({ type: item.name, total: item.amount })))

              const totalFee = filteredFeeWithPaymentProcessing.reduce((a, b) => a + (b.amount || 0), 0)
              setTotalPriceFinish(totalPriceSeats + totalPriceBundles + donation + totalFee + ticketsPrice)
              setFeeLoaded(true)
            })
          : dispatch(getFee(eventID, user ? 'USER' : 'GUEST', ticketsAndSeatToFee, currency, 'Venmo'))
            .then(res => {
              // Filter out 'TicketLyfe Fee' if TLFee is 'PROMOTER'
              const filteredFee = res.filter(item => !(fees?.TLFee === 'PROMOTER' && item.name === 'TicketLyfe Fee'))

              // Filter out 'Payment Processing Fee' if paymentProcessorFee is 'PROMOTER'
              const filteredFeeWithPaymentProcessing = filteredFee.filter(item => !(fees?.paymentProcessorFee === 'PROMOTER' && item.name === 'Payment Processing Fee'))

              setFee(filteredFeeWithPaymentProcessing.map(item => ({ type: item.name, total: item.amount })))

              const totalFee = filteredFeeWithPaymentProcessing.reduce((a, b) => a + (b.amount || 0), 0)
              setTotalPriceFinish(totalPriceSeats + totalPriceBundles + ticketsPrice + donation + totalFee)
              setFeeLoaded(true)
              setAuthorizedBuy(false)
              const paymentSum = totalPriceSeats + totalPriceBundles + ticketsPrice + donation + totalFee
              // if (isResell) {
              // const { quantity, inviteID } = orderedTickets[0]
              // const resellObj = {
              //   quantity: quantity,
              //   inviteID: inviteID,
              //   status: 'ACCEPTED',
              //   paymentType: 'venmo',
              // }
              // user
              //   ? dispatch(inviteTicket(resellObj, callback)).then(() =>
              //     setLoading(false),
              //   )
              //   : dispatch(setInviteTicketGuest(resellObj, callback)).then(() =>
              //     setLoading(false),
              //   )
              // } else {
              const obj = {
                ticketDetails: JSON.stringify([...seatsDetailsToFee, ...ticketDetails, ...bundleDetails]),
                paymentSum: Number(paymentSum.toFixed(2)),
                eventID: eventID,
                inviterID,
                paymentType: 'venmo',
              }
              if (donation) {
                obj.donation = donation
              }
              if (user) {
                // paymentApi.preorderId(obj).then(res => setPreorderCode(res.data.preorderCode))
              } else {
                const { guest } = payment
                const newObj = {
                  ...obj,
                  countryCode: '+1',
                  mobile: guest.phone,
                  email: guest.email.trim(),
                  name: guest.fullName,
                  paymentType: 'venmo',
                }
                paymentApi.guestPreorderId(newObj).then(res => setPreorderCode(res.data.preorderCode))
              }
            })
      } else {
        setFeeLoaded(true)
        setAuthorizedBuy(flag)
        resolve(true)
      }
    })
  }

  useEffect(() => {
    if (!isAuthorizedBuy) {
      handleAuthorization()
    }
  }, [selectedOption, user])
  useEffect(() => {
    if (totalPriceSeats && isAuthorizedBuy || totalPriceTickets && isAuthorizedBuy || totalPriceBundles && isAuthorizedBuy || donation && isAuthorizedBuy) {
      setFeeLoaded(false)
      const ticketDetails = orderedTickets.map(i => {
        const isEarlyBirdActive = i.isEarlyBird && (moment() <= moment(i?.earlyBirdDate))
        return {
          ticketID: i.id,
          quantity: i.count,
          price: isEarlyBirdActive ? i.earlyBirdPrice : i.price,
        }
      })

      const bundleDetails = orderedBundles.map(i => ({
        bundleID: i.id,
        quantity: i.count,
        price: i.price,
      }))

      const ticketsToFee = orderedTickets.map(i => {
        const isEarlyBirdActive = i.isEarlyBird && (moment() <= moment(i?.earlyBirdDate))
        return {
          quantity: i.count,
          price: i?.isDonate ? i.total : isEarlyBirdActive ? i.earlyBirdPrice : i.price,
          isStreaming: i.isStreaming,
          ticketID: i.id,
        }
      })

      const bundlesToFee = orderedBundles.map(i => {
        return {
          quantity: i.count,
          price: i?.isDonate ? i.total : i.price,
          isStreaming: i?.isStreaming,
          bundleID: i.id,
        }
      })

      const seatsDetailsToFee = Object.values(orderedSeatsResult).map(seat => {
        return {
          ticketID: seat.seatCategoryID,
          price: seat.price,
          quantity: seat.seats.length,
        }
      })

      if (donation) {
        ticketsToFee.push({ quantity: 1, price: donation, isDonation: true })
      }
      const ticketsAndSeatToFee = [...seatsDetailsToFee, ...ticketsToFee, ...bundlesToFee]
      if (isAuthorizedBuy || !feeLoaded) {
        selectedOption === 'Stripe'
          ? dispatch(getFee(eventID, user ? 'USER' : 'GUEST', ticketsAndSeatToFee, currency, 'Stripe'))
            .then(res => {
              // Filter out 'TicketLyfe Fee' if TLFee is 'PROMOTER'
              const filteredFee = res.filter(item => !(fees?.TLFee === 'PROMOTER' && item.name === 'TicketLyfe Fee'))

              // Filter out 'Payment Processing Fee' if paymentProcessorFee is 'PROMOTER'
              const filteredFeeWithPaymentProcessing = filteredFee.filter(item => !(fees?.paymentProcessorFee === 'PROMOTER' && item.name === 'Payment Processing Fee'))

              setFee(filteredFeeWithPaymentProcessing.map(item => ({ type: item.name, total: item.amount })))

              const totalFee = filteredFeeWithPaymentProcessing.reduce((a, b) => a + (b.amount || 0), 0)
              setTotalPriceFinish(totalPriceSeats + totalPriceBundles + donation + totalFee + ticketsPrice)
              setFeeLoaded(true)
            })
          : dispatch(getFee(eventID, user ? 'USER' : 'GUEST', ticketsAndSeatToFee, currency, 'Venmo'))
            .then(res => {
              // Filter out 'TicketLyfe Fee' if TLFee is 'PROMOTER'
              const filteredFee = res.filter(item => !(fees?.TLFee === 'PROMOTER' && item.name === 'TicketLyfe Fee'))

              // Filter out 'Payment Processing Fee' if paymentProcessorFee is 'PROMOTER'
              const filteredFeeWithPaymentProcessing = filteredFee.filter(item => !(fees?.paymentProcessorFee === 'PROMOTER' && item.name === 'Payment Processing Fee'))

              setFee(filteredFeeWithPaymentProcessing.map(item => ({ type: item.name, total: item.amount })))

              const totalFee = filteredFeeWithPaymentProcessing.reduce((a, b) => a + (b.amount || 0), 0)
              setTotalPriceFinish(totalPriceSeats + totalPriceBundles + ticketsPrice + donation + totalFee)
              setFeeLoaded(true)
              setAuthorizedBuy(true)
              const paymentSum = totalPriceSeats + totalPriceBundles + donation + totalFee + ticketsPrice
              // if (isResell) {
              // const { quantity, inviteID } = orderedTickets[0]
              // const resellObj = {
              //   quantity: quantity,
              //   inviteID: inviteID,
              //   status: 'ACCEPTED',
              //   paymentType: 'venmo',
              // }
              // user
              //   ? dispatch(inviteTicket(resellObj, callback)).then(() =>
              //     setLoading(false),
              //   )
              //   : dispatch(setInviteTicketGuest(resellObj, callback)).then(() =>
              //     setLoading(false),
              //   )
              // } else {
              const obj = {
                ticketDetails: JSON.stringify([...seatsDetailsToFee, ...ticketDetails, ...bundleDetails]),
                paymentSum: Number(paymentSum.toFixed(2)),
                inviterID,
                eventID: eventID,
                paymentType: 'venmo',
              }
              if (donation) {
                obj.donation = donation
              }
              if (user) {
                paymentApi.preorderId(obj).then(res => setPreorderCode(res.data.preorderCode))
              } else {
                const { guest } = payment
                const newObj = {
                  ...obj,
                  countryCode: '+1',
                  mobile: guest.phone,
                  email: guest.email.trim(),
                  name: guest.fullName,
                  paymentType: 'venmo',
                }
                paymentApi.guestPreorderId(newObj).then(res => setPreorderCode(res.data.preorderCode))
              }
            })
      } else {
        setFeeLoaded(true)
        setAuthorizedBuy(isAuthorizedBuy)
      }
    }
  }, [selectedOption, user])
  const screens = {
    login: () => <Login setAuthorizedBuy={handleAuthorization} onChangeScreen={onChangeScreen} />,
    payment: () => <div className='screen'>
      <FormPayment
        callback={onTicketsBought}
        orderedTickets={orderedTickets}
        orderedSeats={orderedSeats}
        orderedBundles={orderedBundles}
        selectedOption={selectedOption}
        feeLoaded={feeLoaded}
        preorderCode={preorderCode}
        setSelectedOptions={setSelectedOptions}
        currencySymbol={currencySymbol}
        totalPrice={totalPriceFinish || donation}
        totalPriceVenmo={totalPriceFinish}
        donation={donation}
        updateFunc={updateFunc}
        inviteType={inviteType}
        quantity={quantity}
        eventID={eventID}
        action={action}
      />
    </div>,
    tickets: () => <div className='screen'><Tickets price={totalPriceFinish} donationOnly={donationOnly} currencySymbol={currencySymbol} /></div>,
    guest: () => <div className='screen'><LoginGuest onChangeScreen={onChangeScreen} /></div>,
  }
  const ticketsPrice = orderedTickets?.reduce((accumulator, currentTicket) => accumulator + currentTicket.total, 0)
  const closeIcon = <img src={closeIconSvg} alt='close icon' className='closeIcon' />
  const attr = { visible, onCancel, width: 840, maxWidth: 840, footer: null, className: 'modalBuyTicket' }
  useEffect(() => {
    (async () => {
      setIsInit(true)
    })()
    const cats = orderedTickets.map((i, ind) => <Category item={i} key={ind} currencySymbol={currencySymbol} />)
    if (donation) {
      const objDonation = {
        isDonate: true,
        total: donation,
        type: 'Donation',
      }
      cats.push(<Category item={objDonation} key='danation' currencySymbol={currencySymbol} />)
    }
    setCategories(cats)
    !orderedTickets[0] && !orderedSeats[0] && !orderedBundles[0] && donation && setDonationOnly(true)
  }, [])
  useEffect(() => {
    (async () => {
      setIsInit(true)
    })()
    const seatsMap = orderedSeats.map((i, ind) => <Category item={i} key={ind} currencySymbol={currencySymbol} />)
    setSeats(seatsMap)
  }, [])
  useEffect(() => {
    (async () => {
      setIsInit(true)
    })()
    const bundleMap = orderedBundles.map((i, ind) => <Category item={i} key={ind} currencySymbol={currencySymbol} />)
    setBundles(bundleMap)
  }, [])
  return (
    <Modal title='Purchase tickets' {...attr} closeIcon={closeIcon} centered>
      <div className='infoWithCategories'>
        <div className='wrapperCategories'>
          <div className='imgLeft'>
            <img
              src={img}
              onError={e => (e.target.src = cardImg)}
              alt='cardDetail'
              className='img'
            />
            {/* <div className='background' /> */}
            <div className='front'>
              <div className='title'>{eventName}</div>
              <ul className='descriptions'>
                <li className='dateWrapper'>
                  <img src={calendar} alt='calendar' className='dateIcon' />
                  <div className='date'>{moment(startDate).format('MMMM DD, YYYY')}</div>
                </li>
              </ul>
            </div>
          </div>
          <div className='wrapperDescription'>
            <div className='title'>Purchase:</div>
            {isInit
              ? (
                <ul>
                  {categories}
                  {seats}
                  {bundles}
                  {(screen === 'payment' || screen === 'tickets' || screen === 'guest') && feeLoaded === true && inviteType !== 'Private Live Stream' &&
                  fee.map((feeItem, index) => (
                    <Category key={`fee${index}`} item={feeItem} currencySymbol={currencySymbol} />
                  ))}
                  {(screen === 'payment' || screen === 'tickets' || screen === 'guest') && <Category key='total' item={{ type: 'Total', total: totalPriceFinish }} total currencySymbol={currencySymbol} />}
                </ul>
              )
              : <Skeleton active title={false} paragraph={{ rows: 4 }} />}
          </div>
        </div>
        <div className='infoTicketContainer'>
          <div className='infoIcon'>
            <InfoIcon />
          </div>
          <div className='textInfo'>
            This purchase will show up as TICKET LYFE on
            your credit card statement, and All ticket
            proceeds are given to the Promoter of this
            Event, and are non-refundable by Ticket Lyfe--by clicking
            to buy these tickets you accept the conditions.
          </div>
        </div>
        <div className='infoTicketContainer'>
          <div className='infoIcon'>
            <InfoIcon />
          </div>
          <div className='textInfo'>
            Ticket Lyfe Policy is that Ticket Lyfe is not responsible for REFUNDS as the money that the ticket buyer approves to pay is for tickets and is slotted as going to the Promoter of the Event (the Maker of the Ticket); and all refunds are to be gotten from the Event’s Promoter.  By buying your ticket through Ticket Lyfe you are acknowledging that you trust the Promoter to provide what they claim the ticket is for, and that any and all refunds are to be gotten from the Event Promoter.  Ticket Lyfe is not responsible for the Promoter’s refunds. Before--and only before--any funds are paid out to the Promoter, the Promoter does have the capability to refund the Ticket Buyer via the tools provided in the Ticket Lyfe Promoter’s Box Office.  Otherwise, the Promoter and Ticket Buyer are to work out refunds on their own terms, which is outside of the scope of Ticket Lyfe involvement.
          </div>
        </div>
      </div>
      <div className='wrapperScreen'>
        <StepsModal activeScreen={screen} userLogged={user} />
        {screens[screen]()}
      </div>
    </Modal>
  )
}

export default ModalBuyTicket
